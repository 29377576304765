@import '../../../../styling/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paginationContainer {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between !important;
}

.nextButton {
  margin-left: auto !important;
  margin-right: 25% !important;
  text-transform: none !important;
  color: $color-white !important;
  background-color: $color-primary !important;
}
