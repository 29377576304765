//.paper::-webkit-scrollbar {
//  width: 11px;
//}
//.paper{
//  scrollbar-width: thin;
//  scrollbar-color: red;
//}
//.paper::-webkit-scrollbar-track {
//  background: var(--scrollbarBG);
//}
//.paper::-webkit-scrollbar-thumb {
//  background-color: green ;
//  border-radius: 6px;
//  border: 3px solid green;
//}
//.paper {
//  --scrollbarBG: #CFD8DC;
//  --thumbBG: #90A4AE;
//}
.paper {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
    background-color: #343a40;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #76787a;
    border-radius: 3px;

    &:hover {
      background-color: #76787a;
    }
  }
}