@import '../../../styling/colors.scss';

.cancelButton {
  border: 2px solid $color-primary !important;
  color: $color-primary !important;
}

.confirmButton {
  color: $color-white !important;
  background-color: $color-primary !important;
}
