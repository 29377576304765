@import '../../../../../../../styling/colors.scss';

.container {
  border-top: 3px solid $color-gray-light;
  margin-top: 10px;
  padding-top: 16px;
  margin-left: 100px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-left: 0;
  }
}

.textDetails {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: left;
}

.image {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  border-radius: 15px;
}

.textfieldButton,
.deleteButton {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
  text-transform: none !important;
  height: 40px !important;
}

.deleteButton {
  margin-left: auto !important;
  background-color: $color-gray !important;

  @media (max-width: 600px) {
    margin-left: 0 !important;
  }
}

.textfield {
  margin-top: 10px !important;
}

.title {
  font-weight: 500;
  font-size: 16px;
  color: $color-gray-dark;
}
