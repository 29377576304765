@import '../../../styling/colors.scss';

.mainContainer {
  flex: 0 0 20%;
}
.container {
  width: 300px;
  border-radius: 10px;
  border: 3px solid $color-primary;
  background-color: $color-gray-light;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1000x) {
    background-color: $color-white;
  }
}

.licenceTitle {
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
}

.recommendationSection {
  margin-top: 20px;
  min-height: 60px;
  height: 100%;
  font-size: 14px;
  font-style: italic !important;
  text-align: center;
}

.coversContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.coversContainer div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.optionsContainer {
  margin-bottom: 20px;
}

.type {
  font-size: 20px;
  margin-bottom: 20px;
}

.priceContainer {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.price {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
  font-size: 2.0243rem;
  text-align: center;
}

.perImage {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  font-size: 1.1rem;
}

.button {
  width: 50%;
  text-transform: none !important;
  background-color: $color-primary !important;
  height: 56px;
  font-size: 16px !important;
  color: $color-white !important;
  margin: 10px 0;
}

.isCenter {
  transform: scale(1.1);
  background-color: $color-white;
  z-index: 3;

  @media (max-width: 1000px) {
    transform: scale(1);
  }
}
