@import '../../../../../../../styling/colors.scss';

.content {
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 600px) {
    padding: 5px;
  }
}
.containerSubmitTax {
  display: flex;
  max-width: 300px;
  flex-direction: column;
}
.containerPayment {
  display: flex;
  max-width: 500px;
  flex-direction: column;
}
.requestAmount {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 18px;

}
.labelStyleTax {

  margin-top: 10px;
  margin-bottom: 10px;
}

.paperPayment {
  padding: 15px 5px;
  color: $color-primary !important;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
}
.contentEmails {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap:6px
}
.textAmount {
  width: 150px;
  max-width: 250px
}
.tableHistory {
  //max-width: 500px;
}