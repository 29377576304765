@import '../../../../../../../styling/colors.scss';

.container {
  background-color: $color-gray-light;
  padding: 20px;
  margin: 20px 0;
}

.top {
  display: flex;
}

.logo {
  width: 75px;
  height: 75px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}

.bottom {
  display: flex;
  justify-content: center;
  gap: 20%;

  @media (max-width: 600px) {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
  }
}

.italicText {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
}

.downloadButton {
  margin-top: 10px;
  width: 100px !important;
  background-color: $color-secondary !important;
  color: $color-white !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}

.textAndButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  //align-items: center;
}
