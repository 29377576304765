@import '../../../styling/colors.scss';
@import '../../../styling/animations.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 2%;
}

.textContainer {
  width: 80%;
  color: $color-white;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: center;

  h1,
  h3,
  button {
    animation-name: slideInFromBottom;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  h1 {
    animation-delay: 0s;
  }

  h3 {
    animation-delay: 0.3s;
  }

  button {
    animation-delay: 0.6s;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.rightImage {
  width: 33%;
  height: auto;
  animation-name: slideInFromBottom;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 0s;
  @media (max-width: 800px) {
    display: none;
  }
}

.latestSearchesContainer {
  display: flex;
  justify-content: center;
}
