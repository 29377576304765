@import '../../../../../../../styling/colors.scss';

.totalStatsContainer {
  display: flex;
  gap: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}

.text,
.boldText {
  font-size: 18px;
}

.boldText {
  margin-left: 5px;
  font-weight: 700;
}

.table {
  margin-top: 30px;
  box-shadow: none !important;
  border-collapse: unset !important;
}

.tableRow {
  border-top: 3px solid $color-gray-light !important;
}

.tableRow {
  font-weight: 900 !important;
}

.pagination {
  margin-top: 20px;
}

.tableCell {
  font-weight: 600 !important;
  font-size: 15px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}

.tableCellContent {
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}

.filtersContainer {
  width: 500px;
  margin-top: 20px;
  display: flex;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    width: auto;
  }
}
