@import '../../../../styling/colors.scss';

.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
}

.content {
  width: 304px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome {
  font-size: 32px !important;
  font-weight: bold !important;
  margin-bottom: 24px !important;
}

.textField,
.button {
  margin: 8px 0 !important;
  width: 304px !important;
}

.button {
  text-transform: none !important;
  background-color: $color-primary !important;
  height: 56px;
  font-size: 16px !important;
  color: $color-white !important;
}
