.iconBox {
  width: 4rem;
  height: 4rem;
  align-items: center;
  text-align: center;
  display: flex;
  color: #fff;
  justify-content: center;
  border-radius: 0.375rem;
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
}
.gridIcon {
  display: flex;
  justify-content: flex-end;
}
.textTitle {
  font-weight: 300;
  line-height: 1.5;
}
.textSubtitle {
  font-weight: 700;
  line-height: 1.375;
  font-size: 1.25rem;
}