@import '../../../../../../../styling/colors.scss';

.tabContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.uploader div {
  margin: 0 !important;
  width: 250px !important;
  height: 115px !important;
  margin-right: 0px !important;
}

.tabs div div {
  display: flex !important;
  flex-wrap: wrap !important;
}
.container {
  width: 100%;
}
.containerButtonsRight {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0px;
  justify-content: center;
  align-items: flex-start;
}
.button {
  margin: 5px 0 !important;
  text-transform: none !important;
  background-color: $color-primary !important;
  color: $color-white !important;
}
.button:disabled  {
  background-color: $color-gray !important;
}
