@import '../../../../../../../styling/colors.scss';

.step {
  color: $color-primary;
  font-size: 18px;
  margin-right: 30px;
  font-weight: 600;
}

.title {
  color: $color-gray;
  font-size: 18px;
  font-weight: 600;
}

.titleContainer {
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.description {
  margin-top: 20px;
  font-size: 16px;
}

.topElementsContainer {
  margin-top: 40px;
  display: flex;
  //width: 80vw;
  overflow-x: auto;

  @media (max-width: 600px) {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;
  }
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.button {
  text-transform: none !important;
  background-color: $color-gray !important;
  color: $color-gray-light !important;
}

.selectedButton {
  text-transform: none !important;
  background-color: $color-primary !important;
  color: $color-gray-light !important;
}

.checkbox {
  white-space: nowrap !important;
}

.globalUploaderContainer {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 3px solid $color-gray-light;
  border-bottom: 3px solid $color-gray-light;
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.outsideButton {
  width: 120px !important;
  text-transform: none !important;
  background-color: $color-gray !important;
  color: $color-gray-light !important;
}

.elements {
  margin-top: 10px;
}

.elementContainer {
  // border-bottom: 3px solid $color-gray-light;
  padding-top: 20px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.bottomButtons {
  display: flex;
  margin-top: 20px;
  gap: 30px;
}

.releaseButton {
  margin-bottom: 5px !important;
  width: 200px !important;
  text-transform: none !important;
  background-color: $color-gray !important;
  color: $color-gray-light !important;
}

.toLicensingButton {
  margin: 20px 0 !important;
  text-transform: none !important;
  background-color: $color-primary !important;
  color: $color-white !important;
}

.releasesContainer {
  margin-bottom: 10px;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.fileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.deleteButton {
  text-transform: none !important;
  background-color: $color-primary !important;
  color: $color-white !important;
}

.imageAndUpload {
  display: flex;
  flex-wrap: wrap;
  // @media (max-width: 600px) {
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  // }
}
.imageAndUploadReleasesList { 
  display: flex;
  flex-direction: row; 
  gap: 2px;
}
.containerStepFourAlerts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.containerFormControl {
  width: 200px;
}
