@import '../../../styling/colors.scss';
@import '../../../styling/globalStyles.scss';

.drawerContainer {
  background-color: $color-gray-dark !important;
  height: 100%;
}

.divider {
  background-color: $color-white;
}

.buttonSelected {
  @extend .buttonSelected;
  border-radius: 5px !important;
}

.subItemButton {
  color: $color-white !important;
  height: 30px !important;
  width: 150px !important;
  margin-left: 30px !important;
}
