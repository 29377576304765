@import '../../../styling/colors.scss';
@import '../../../styling/animations.scss';

.container {
  display: flex;
  margin-bottom: 70px;
  width: 80%;
}

@media (min-width: 600px) {
  .container {
    width: calc(100% / 2);
  }
}

@media (min-width: 900px) {
  .container {
    width: calc(100% / 3);
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  transition: background-color 0.5s ease-in-out;
  flex-shrink: 0;
}

.left svg {
  width: 48px;
  height: 48px;
  color: $color-primary;
  transition: background-color 0.5s ease-in-out;
}

.left:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  svg {
    width: 48px;
    height: 48px;
    color: $color-white;
    transition: opacity 0.5s ease-in-out;
  }
}

.right {
  color: $color-gray-dark;
  max-width: 250px;
  margin-left: 20px;
}

.title {
  text-transform: uppercase;
  margin: 0;
}

.description {
  margin-top: 15px;
}
