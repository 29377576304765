@import '../../../styling/colors.scss';

.container {
  margin: 20px 0;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.title {
  color: $color-gray;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.contentContainer {
  margin-top: 8px;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}

.fileDropArea div {
  height: 215px;
  width: 300px;
}

.image {
  max-width: 105px;
  max-height: 105px;

  @media (max-width: 600px) {
    max-width: 200px;
    max-height: 200px;
  }
}

.filesContainer {
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-left: 0;
  }
}
