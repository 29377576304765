@import '../../../../styling/colors.scss';

.gridContainer {
  border-radius: 10px;
  display: flex !important;
  justify-content: center;
}

.topContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchBar {
  margin-bottom: 16px;
  width: 96vw;
  padding-top: 16px;
  border-radius: 10px;
}

.searchContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.search {
  width: 60% !important;
}

.textfield {
  margin: 8px 0 !important;
}
.datePicker {
  margin: 8px 0 !important;
  z-index: 9999;
}

.checkbox,
.radiobox {
  height: 30px;
}

.sort {
  border-radius: 10px !important;
  background-color: $color-gray-light;
  width: 175px !important;
  margin: 10px !important;
}

.menuItem {
  background-color: $color-gray-light !important;
}

.textfieldButton {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  text-transform: none !important;
  height: 40px !important;
  margin-top: 10px !important;
  width: 100% !important;
}
