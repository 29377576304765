@import '../../../styling/colors.scss';

.container {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid $color-gray-light;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
}

.image {
  border: 1px solid $color-gray;
  max-width: 120px;
  max-height: 120px;
}

.left,
.right,
.deleteButton {
  margin: 0px 10px;
}

.reasonForRejection {
  color: $color-gray;
  font-weight: 600;
  font-size: 16px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.reasonForRejectionText {
  border: 3px solid $color-gray-light;
  width: 300px;
  padding: 10px;
  height: 94px;
  word-wrap: break-word;
  overflow: auto;
}

.deleteButton {
  height: 50px !important;
  align-self: flex-end;
  margin-bottom: 10px !important;
  color: $color-primary !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.imageText {
  font-weight: 500;
  color: $color-gray;
  font-size: 14px;
}
