@import '../../../styling/colors.scss';

.content {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 300px;
}

.cancelButton {
    border: 2px solid $color-primary !important;
    color: $color-primary !important;
}

.confirmButton {
    color: $color-white !important;
    background-color: $color-primary !important;
}
