@import '../../../../styling/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer {
  margin-top: 20px;
  align-self: flex-start;
}

.buttons {
  display: flex;
  align-self: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.image {
  max-width: 100%;
  object-fit: contain;
}
