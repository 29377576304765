@import '../../../../styling/colors.scss';

.container {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $color-gray-light;
  display: flex;
  padding-left: 2vw;
  padding-right: 2vw;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.leftContainer {
  height: 70vh;
  padding: 10px;
  //position: sticky;
  top: 20px;

  display: flex;
  justify-content: center;
  border: 1px solid $color-gray-light;
  background-color: $color-white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px 15px 15px 15px;

  @media (max-width: 600px) {
    position: relative;
    top: auto;
    display: flex;
    justify-content: center;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    height: max-content;
    padding: 0px;
    overflow: hidden;
  }
}

.rightContainer {
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.listContainer {
  width: 200px !important;
  overflow: auto;
  display: flex;
  position: sticky;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    width: 100% !important;
    display: flex;
    justify-content: center !important;
  }
}

.selectMobile {
  width: 100%;
}
.rootPaperSelect {
  left: -8px
}

.list {
  flex-grow: 1;
}

.radioBoxForm {
  margin-left: 30px !important;
}

.radiobox {
  margin-left: 0 !important;
}

.radiobox span {
  font-size: 13px !important;
}
.rootPaper {
  width: 100%;
}
.MuiPopover-paper {
  width: 100%;
}