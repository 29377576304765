@import '../../../../../../../styling/colors.scss';

.topContainer {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 15px;
    justify-content: center;
    align-items: center;
  }
}

.title {
  font-weight: 600;
  font-size: 22px;
  color: $color-gray-dark;
}

.textfields {
  display: flex;
  flex-direction: column;
}

.textfield {
  margin: 10px 0 !important;
  width: 300px !important;

  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.textfieldButton {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
  text-transform: none !important;
  height: 40px !important;
}
