@import '../../../../../../../styling/colors.scss';

.step {
  color: $color-primary;
  font-size: 18px;
  margin-right: 30px;
  font-weight: 600;
}

.title {
  color: $color-gray;
  font-size: 18px;
  font-weight: 600;
}

.description {
  margin-top: 20px;
  font-size: 16px;
}

.titleContainer {
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
