@import '../../../../../styling/colors.scss';

.container {
  margin-top: -30px;
  display: flex;
  align-items: center;
  gap: 50px;
}

.title {
  font-weight: 500;
  font-size: 24px !important;
  color: $color-gray-dark;
}

.description {
  font-weight: 500;
  font-size: 13px !important;
  color: $color-gray-dark;
}
