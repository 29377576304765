@import '../../../styling/animations.scss';
@import '../../../styling/colors.scss';

.infoContainer {
  display: flex;
  justify-content: center;
  background-color: $color-gray-light;
  padding-top: 5%;
  padding-bottom: 5%;
  gap: 0;
  @media (max-width: 700px) {
    display: block;
    padding-left: 10%;
    gap: 0;
  }
}

.textSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 280px;
  margin-left: 10px;
  margin-right: 10px;
  word-wrap: break-word;
  @media (max-width: 700px) {
    gap: 0.3rem;
  }
}

.title {
  font-weight: normal;
  text-transform: uppercase;
  margin: 16px 0 0 0 !important;
}

.textBold {
  font-weight: bold;
}

.iconAndText {
  display: flex;
  align-items: center;
}
