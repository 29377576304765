@import '../../../styling/colors.scss';
@import '../../../styling/animations.scss';

.container {
  display: flex;
  align-items: center;
  height: 85px;
  background-color: $color-gray-dark;
  border-top: 5px solid $color-secondary;
  padding-left: 2vw;
}

.text {
  color: $color-white;
  font-size: 14px !important;
}
