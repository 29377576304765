@import '../../../../styling/animations';
@import '../../../../styling/colors.scss';
@import '../../../../styling/globalStyles.scss';

.container {
  padding-top: 50px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-gray-light;
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

@media (min-width: 600px) {
  .featuresContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
