@import '../../../../../../../styling/colors.scss';

.topContent {
  display: flex;
  align-items: center;
  gap: 50px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.backIcon {
  margin-top: -25px !important;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.content {
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 40px;

  @media (max-width: 1200px) {
    padding: 20px;
  }

  @media (max-width: 600px) {
    padding: 5px;
  }
}

.top {
  display: flex;
  align-items: center;
  gap: 50px;
}

.searchBarContainer {
  flex-grow: 1;
}

.searchBar {
  margin-left: 20px;
}

.allReleases {
  color: $color-gray-dark;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}

.releasesFormButton {
  text-transform: none !important;
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
}

.table {
  margin-top: 30px;
  box-shadow: none !important;
  border-collapse: unset !important;

  @media (max-width: 600px) {
    overflow-x: auto;
  }
}

.tableRow {
  border-top: 3px solid $color-gray-light !important;
}

.tableRow {
  font-weight: 900 !important;
}

.pagination {
  margin-top: 20px;
}
