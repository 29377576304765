
.input {
  padding: 2px 4px;
  width: 34px;
  height: 34px;
  align-items: center;
  text-align: center;
}
.inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
