@import '../../../../styling/colors.scss';
@import '../../../../styling/animations.scss';
@import '../../../../styling/globalStyles.scss';

.container {
  padding-top: 50px;
  padding-left: 17.5%;
  padding-right: 17.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-white;
}

.description {
  @extend .description;
  align-self: center;
  justify-self: center;
  text-align: center;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (min-width: 600px) {
  .imageContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: $color-primary;
}
