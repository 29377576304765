@import '../../../styling/colors.scss';

.button {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
  text-transform: none !important;
  height: 40px !important;
}

.image {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain !important;
}
