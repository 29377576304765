@import '../../../../styling/colors.scss';

.imageContainer {
  position: relative;
}

.image {
  width: 100% !important;
  height: auto !important;
  max-height: 730px;
}

:global {
  .splide__arrow--prev,
  .splide__arrow--next {
    background-color: $color-secondary;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    color: $color-white;

    display: none;
  }

  .splide__arrow--prev svg,
  .splide__arrow--next svg {
    fill: $color-white;
  }
}
