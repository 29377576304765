@import '../../../../../../../../styling/colors.scss';

.propertyPdf,
.modelPdf,
.minorPdf {
  display: flex;
  gap: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }
}
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.6;
  padding: 10px;
  left: -5px;
  top: -5px;
  z-index: 10;
}
.termsAndConditions {
  background-color: $color-gray-light;
  padding: 10px;
  border: 2px solid $color-gray-dark;
  white-space: pre-line;
  max-width: 50%;
  font-size: 14px;

  @media (max-width: 600px) {
    max-width: 100%;
  }
}

.image {
  max-width: 100px;
  max-height: 100px;
}
.contentFileUpload {
  display: flex;
  justify-content: space-between;
}

.textfield {
  margin: 5px 0 !important;
}

.titleText {
  font-size: 16px;
  font-weight: 600;
}

.newPropertyButton {
  margin: 10px 0 !important;
  text-transform: none !important;
  color: $color-white !important;
  background-color: $color-primary !important;
}
.deleteModelButton {
  margin: 10px 10px !important;
  text-transform: none !important;
  color: $color-white !important;
  background-color: $color-gray-dark !important;
}

.errorText {
  color: $color-primary !important;
  margin-left: 14px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
}
.button {
  margin: 5px 0 !important;
  text-transform: none !important;
  background-color: $color-primary !important;
  color: $color-white !important;
}
.button:disabled  {
  background-color: $color-gray !important;
}
.containerInlineButtons {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
}