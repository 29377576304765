@import '../../../../styling/colors.scss';

.imageContainer {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1200px) {
    gap: 0px;
    flex-direction: column;
  }
}

.searchBar {
  margin: 16px;
}

.imageWrapper {
  padding: 20px;
  display: flex!important;
  flex-direction: column!important;
  justify-content: center!important;
  align-items: center!important;
}

.image {
  //max-height: 500px;
  object-fit: contain !important;
  max-width: 50%!important;
  width: 100%!important;
  object-fit: cover!important;
}

.userContainer {
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.userImage {
  display: flex;
  align-items: center;
  gap: 5px;
}

.accordions {
  padding-top: 20px;
}

.redButton,
.fastDownloadButton {
  padding-right: 16px;
  padding-left: 16px;
  text-transform: none !important;
  font-size: 16px !important;
  //background-color: $color-primary !important;
  //color: $color-white !important;
  border-radius: 20px !important;
}
.buttonShare {
  text-transform: none !important;
  background-color: $color-gray !important;
}

.radiobox {
  margin-left: 0 !important;
}

.radiobox span {
  font-size: 15px !important;
}

.buttonsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.fastDownloadButton {
  width: 100% !important;
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.rightContainer {
  padding: 0 20px 20px 20px;
}

.text {
  font-weight: 600;
}

.relatedKeywordsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  margin: 15px;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
}

.minorTitle {
  font-size: 1.2rem;
  font-weight: 500;
}

.imageDetailsContainer {
  display: flex;
  gap: 10px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.stockPhotoId {
  max-width: 400px;
  padding-left: 10px;
  padding-right: 10px;
}

.description {
  //max-width: 400px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  word-wrap: break-word;
}

.photoFormats {
  max-width: 400px;
}

.accordion {
  border-radius: 10px !important;
 width: 100%;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.accordionSummary {
  border-bottom: 1px solid #000 !important; /* Adjust the px as needed */
}
