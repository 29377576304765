@import '../../../styling/colors.scss';

.modelReleaseList {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  overflow: 'auto';
  margin-top: 5px;
}

.modelReleaseItem {
  min-width: 50px;
}
.releaseContainer {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 5px;
}
.deleteReleaseButton:hover {
  color: $color-primary;
}