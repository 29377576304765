@import '../../../../../.././../styling/colors.scss';

.content {
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 15px;
  }
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.title {
  font-weight: 500;
  font-size: 22px;
  color: $color-gray-dark;
}

.pagination {
  margin-top: 40px;
}
