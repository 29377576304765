@import '../../../../../../../styling/colors.scss';

.top {
  display: flex;
  gap: 10px;
}

.backIcon {
  margin-top: -25px !important;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
}

.content {
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 40px;

  @media (max-width: 1200px) {
    padding: 20px;
  }

  @media (max-width: 600px) {
    padding: 15px;
  }
}

.nextStepButton {
  margin: 20px 0 !important;
  text-transform: none !important;
}
