@import '../../../../styling/colors.scss';

.container {
  min-width: 100% !important;
}

.containerBackground {
  border-radius: 15px;
  height: 400px;
  background-color: black;
}

.backgroundImage {
  position: relative;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.title {
  color: $color-primary;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.userDetailsContainer {
  margin-left: 20px;
  margin-right: 20px;
  transform: translate(0%, -50%);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  background-color: $color-white;
}

.textContentContainer {
  margin-left: 20px;
  @media (max-width: 600px) {
  font-size: 14px;
  }
}

.profilePic {
  border-radius: 15px;
  width: 60px;
  height: 60px;
}

.userTextContainer {
  display: flex;
  justify-content: center;
}

.statusContainer {
  display: flex;
  gap: 15px;
}

.userName {
  font-weight: 500;
}

.settingsButton {
  margin-left: auto !important;
}
