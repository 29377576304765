@import '../../../styling/colors.scss';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background: rgba(12, 18, 28, 0.38);
    border-radius: 5px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.categoryContainer {
  @media (max-width: 600px) {
    width: 100%;
  }
}

.categoryContainer div button {
  height: 40px !important;
  border-radius: 8px !important;
}

.searchBar {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  padding-right: 0px !important;


  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.searchBar > div {
  margin: 10px;
}

.endIconButton {
  padding-top: 10px !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}
