.container {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 0 !important;
  padding: 0 !important;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}
