@import '../../../../styling/colors.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.gridContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 2rem;

  @media (max-width: 600px) {
    flex-direction: column !important;
    gap: 0 !important;
  }
}

.gridContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome {
  font-size: 32px !important;
  font-weight: bold !important;
  margin-bottom: 24px !important;
}

.textField,
.button {
  margin: 10px 0 !important;
  width: 304px !important;
}

.sellerTextfield {
  margin: 10px 0 !important;
  width: 100% !important;
}

.button {
  text-transform: none !important;
  background-color: $color-primary !important;
  height: 56px;
  font-size: 16px !important;
  color: $color-white !important;
  margin: 10px 0;
}

.no_account {
  display: flex;
  gap: 5px;
}

.text {
  display: flex !important;
  flex-direction: row !important;
  gap: 20px !important;
  margin-top: 10px !important;
  margin-bottom: 10px;
  font-size: 14px;

  @media (max-width: 600px) {
    flex-direction: column !important;
    gap: 0 !important;
  }
}

.linkText {
  color: $color-primary !important;
  text-decoration: none !important;
}

.checkbox {
  height: 30px !important;
}
