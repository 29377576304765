@import '../../../../../../../styling/colors.scss';

.container {
  margin: 16px;
  padding-top: 10px;
  border-top: 3px solid $color-gray-light;
}

.lightboxDetails {
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.image {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}

.textfieldButton,
.deleteButton {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
  text-transform: none !important;
  height: 40px !important;
}

.deleteButton {
  margin-left: auto !important;
  background-color: $color-gray !important;

  @media (max-width: 600px) {
    margin-left: 0 !important;
  }
}
.buttonLightbox {
  min-height: 40px;
  border-radius: 20px !important;
}

.deleteButtonLightbox {
  background-color: $color-gray !important;
  border-radius: 20px !important;
  min-height: 40px;

  @media (max-width: 600px) {
    margin-left: 0 !important;
  }
}

.textfield {
  width: 200px !important;
}

.title {
  font-weight: 500;
  font-size: 18px;
  color: $color-gray-dark;
}