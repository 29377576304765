@import '../../../../../../../styling/colors.scss';
.content {
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 5px;
  }
}
.contentCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px
}