@import '../../../../../../../styling/colors.scss';

.topContainer {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
}

.detailsContainer {
  height: 40px;
  display: flex;
  gap: 30px;
  align-items: center;
  margin-left: 40px;

  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    flex-direction: column;
    gap: 10px;
  }
}

.details,
.detailsBold {
  font-size: 18px;
  margin-right: 5px;
}

.detailsBold {
  font-weight: 700;
}

.table {
  margin-top: 30px;
  box-shadow: none !important;
  border-collapse: unset !important;
}

.tableRow {
  border-top: 3px solid $color-gray-light !important;
}

.tableRow {
  font-weight: 900 !important;
}

.pagination {
  margin-top: 20px;
}

.tableCell {
  font-weight: 600 !important;
  font-size: 15px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}

.tableCellContent {
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}

.image {
  width: 75px;
  height: 75px;
}
