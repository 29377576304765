@import '../../../../../../../styling/colors.scss';

.topContainer {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
}

.topContent {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.container {
  margin-left: 20px;
  margin-right: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 15px;
    justify-content: center;
    align-items: center;
  }
}

.title {
  font-weight: 600;
  font-size: 22px;
  color: $color-gray-dark;
}

.searchBar {
  max-width: 250px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
}

.bold {
  font-weight: 600;
}

.table {
  box-shadow: none !important;
  border-collapse: unset !important;
  overflow-x: auto !important;

  @media (max-width: 600px) {
    overflow-x: auto;
  }
}

.titleCell {
  font-weight: 600 !important;
}

.tableRow {
  border-top: 3px solid $color-gray-light !important;
}

.tableRow {
  font-weight: 900 !important;
}

.pagination {
  margin-top: 20px;
}

.button {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
  text-transform: none !important;
}

.cellContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.downloadInvoiceCell {
  color: $color-blue;
}

.textButton {
  padding: 0 !important;
  color: $color-blue !important;
}

.textfieldButton {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
  text-transform: none !important;
  margin-left: auto !important;
}
