@import '../../../styling/colors.scss';

.container {
  margin-right: 15px;
  margin-bottom: 15px;
}

.image {
  border: 1px solid $color-gray;
  max-width: 175px;
  max-height: 175px;
}

.imageText {
  font-weight: 500;
  color: $color-gray;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.closeIconButton {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1000 !important;
}
