@import '../../../../../../../styling/colors.scss';

.step {
  color: $color-primary;
  font-size: 18px;
  margin-right: 30px;
  font-weight: 600;

  @media (max-width: 600px) {
    margin-right: 0;
  }
}

.titleContainer {
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.title {
  color: $color-gray;
  font-size: 18px;
  font-weight: 600;
}

.description {
  margin-top: 20px;
  font-size: 16px;
}

.topElementsContainer {
  margin-top: 40px;
  display: flex;
  width: 100%;
  overflow-x: auto;

  @media (max-width: 600px) {
    flex-direction: column;
    width: auto;
  }
}

.element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.button {
  text-transform: none !important;
  background-color: $color-gray !important;
  color: $color-gray-light !important;
}

.selectedButton {
  text-transform: none !important;
  background-color: $color-primary !important;
  color: $color-gray-light !important;
}

.checkbox {
  white-space: nowrap !important;
}

.globalDescriptionAndKeywordsContainer {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 3px solid $color-gray-light;
  border-bottom: 3px solid $color-gray-light;
  display: flex;
  gap: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.descriptionAndKeywordsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bottomButtons {
  display: flex;
  margin-top: 20px;
  gap: 30px;
}

.outsideButton {
  width: 120px !important;
  text-transform: none !important;
  background-color: $color-gray !important;
  color: $color-gray-light !important;
}
.outsideButtonSave {
  width: 120px !important;
  text-transform: none !important;
  color: $color-gray-light !important;
}

.elements {
  margin-top: 10px;
}

.elementContainer {
  border-bottom: 3px solid $color-gray-light;
  padding-top: 20px;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}

.elementButtonsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
    justify-content: flex-start;

  @media (max-width: 600px) {
    gap: 20px;
  }
}

.elementButton {
  margin-bottom: 40px !important;
  width: 80px !important;
  text-transform: none !important;
  background-color: $color-gray !important;
  color: $color-gray-light !important;
}
