@import '../../../../../../../styling/colors.scss';

.top {
  display: flex;
  gap: 10px;
}

.backIcon {
  margin-top: -25px !important;
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.content {
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 20px;

  @media (max-width: 600px) {
    padding: 5px;
  }
}

.table {
  margin-top: 30px;
  box-shadow: none !important;
  border-collapse: unset !important;
}

.tableRow {
  border-top: 3px solid $color-gray-light !important;
}
.tableRowMonth {
  background-color: #ababab;
}

.tableRow {
  font-weight: 900 !important;
}

.pagination {
  margin-top: 20px;
}

.tableCell {
  font-weight: 600 !important;
  font-size: 15px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}

.tableCellContent,
.tableCellContentDate {
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
  display: contents;
}

.tableCellContentMonth{
  font-weight: bold;
  font-size: 14px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}
.tableCellContentDate {
  min-width: 80px;
  color: $color-blue !important;
  cursor: pointer;
}

.bottomCells {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.bottomCellsContent {
  display: flex;
  justify-content: space-between;
}

.bottomCells div {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: $color-gray !important;
}

.filterBar {
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.filterBarLeft,
.filterBarRight {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.unpaidEarnings {
  font-weight: 600 !important;
  font-size: 18px !important;
  text-align: center !important;
}

.exportExcelButton {
  text-transform: none !important;
  width: 200px !important;
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
}
.exportExcelDisabledButton {
  text-transform: none !important;
  width: 200px !important;
  font-size: 16px !important;
  background-color: #6c757d;
  color: $color-white !important;
  border-radius: 20px !important;
}

.selectTextField {
  max-width: 150px;
}
