@import '../../../styling/colors.scss';

.cancelButton {
  border: 2px solid $color-primary !important;
  color: $color-primary !important;
}

.confirmButton {
  color: $color-white !important;
  background-color: $color-primary !important;
}
.textfields {
  display: flex;
  flex-direction: column;
}

.textfield {
  margin: 10px 0 !important;
  //width: 300px !important;
  //flex-grow: 1;

  @media (max-width: 600px) {
    width: 100% !important;
  }
}