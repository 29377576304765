@import '../../../../../../../styling/colors.scss';

.table {
  box-shadow: none !important;
  border-collapse: unset !important;
}

.tableRow {
  border-top: 3px solid $color-gray-light !important;
}

.tableRow th {
  font-weight: 700 !important;
}

.pagination {
  margin-top: 20px;
}

.tableCell {
  font-weight: 600 !important;
  font-size: 15px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}

.tableCellContent {
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
  border-left: 3px solid $color-gray-light;
  border-right: 3px solid $color-gray-light;
}

.titleDescriptionAndKeywords {
  color: $color-gray;
  font-weight: 600;
  margin-top: 10px;
}

.imageContainer div {
  margin: 0 !important;
}

.descriptionContainer {
  border: 5px solid $color-gray-light;
  padding: 16px;
}

.description {
  max-width: 300px;
  word-wrap: break-word;
}

.deleteButton {
  text-transform: none !important;
  color: $color-white !important;
  background-color: $color-primary !important;
}

.disabledDeleteButton {
  text-transform: none !important;
  color: $color-gray-light !important;
  background-color: $color-gray !important;
}
