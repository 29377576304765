@import '../../../styling/colors.scss';

.details {
  max-width: 250px !important;
  border-radius: 10px !important;
  background-color: $color-gray-light !important;
  z-index: 100;
  padding: 16px;
}

.summary {
  color: $color-gray;
}

.button {
  text-transform: none !important;
  background-color: $color-gray-light !important;
  border-radius: 10px !important;
  margin: 10px !important;
}
