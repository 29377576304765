@import '../../../../../../../styling/colors.scss';

.container {
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.content {
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 40px;
}

.nextStepButton {
  margin: 20px 0 !important;
  text-transform: none !important;
  background-color: $color-primary !important;
  color: $color-white !important;
}

.stepper {
  margin-bottom: 10px;
}
