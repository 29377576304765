@import '../../../../../../../styling/colors.scss';

.contentPaper {
  width: max-content;
  padding: 10px;
}
.contentHistory {
  display: flex;
  flex-wrap: wrap;
  gap: 8px
}
.topContainer {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
}

.container {
  margin-left: 20px;
  margin-right: 20px;
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
  border-radius: 15px;
  background-color: $color-white;
  padding: 40px;

  @media (max-width: 600px) {
    padding: 15px;
    justify-content: center;
    align-items: center;
  }
}

.title {
  font-weight: 600;
  font-size: 22px;
  color: $color-gray-dark;
  margin-top: 10px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 7.5px;
}

.bold {
  font-weight: 600;
  margin-top: 5px;
  margin-right: 10px;
}
