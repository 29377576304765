.button {
  color: $color-white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  text-transform: none !important;
}

.buttonSelected {
  @extend .button;
  background-color: $color-primary !important;
}

.title {
  text-transform: uppercase;
  text-align: center;
  font-size: 36px !important;
  color: $color-gray-dark;
  font-weight: 620;
}

.description {
  color: $color-gray-dark;
  text-align: center;
}
