@import '../../../../../../../styling/colors.scss';

.container {
  @media (max-width: 600px) {
    display: flex !important;
    flex-direction: column !important;
  }
}

.deleteAllButton {
  font-size: 16px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  border-radius: 20px !important;
  text-transform: none !important;
}

.pagination {
  margin-top: 20px;
}
