@import '../../../styling/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.subscriptionsContainer,
.creditsContainer {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 20px;
  }
}

.creditsContainer {
  gap: 20px;
}

.titleSubscription,
.titleCredits {
  margin-top: 50px;
  font-size: 30px;
  font-weight: 700;
  color: $color-gray-dark;
}

.titleSubscription {
  margin-bottom: 20px;
}
.textCenter {
  text-align: center;
}
