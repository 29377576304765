@import '../../../../../../../styling/colors.scss';

.step {
  color: $color-primary;
  font-size: 18px;
  margin-right: 30px;
  font-weight: 600;
}

.titleContainer {
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.title {
  color: $color-gray;
  font-size: 18px;
  font-weight: 600;
}

.optionsContainer {
  display: flex;
  margin-top: 30px;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
}

.options {
  display: flex !important;
  flex-direction: row !important;
  gap: 20px !important;
  flex-shrink: 1 !important;

  @media (max-width: 600px) {
    flex-direction: column !important;
    flex-shrink: 0 !important;
    gap: 5px !important;
  }
}

.checkbox,
.selectAllCheckbox {
  height: 30px !important;
  white-space: nowrap !important;

  @media (max-width: 600px) {
    height: auto !important;
    white-space: pre-wrap !important;
  }
}

.selectAllCheckbox {
  margin-right: 60px !important;

  @media (max-width: 600px) {
    margin-right: 0 !important;
  }
}

.textfield {
  margin: 0 !important;
  width: 200px !important;
}

.elements {
  margin-top: 10px;
  border-bottom: 3px solid $color-gray-light;
}

.elementContainer {
  border-top: 3px solid $color-gray-light;
  padding-top: 20px;
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}

.button,
.outsideButton {
  text-transform: none !important;
  background-color: $color-gray !important;
  color: $color-gray-light !important;
}

.button {
  width: 80px !important;
  margin-top: 10px !important;
  margin-bottom: 40px !important;
}

.outsideButton {
  width: 120px !important;
}

.elementTextfield {
  align-self: flex-end;
  margin: 0 !important;
  width: 200px !important;
}

.elementOptionsContainer {
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
}

.elementButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}

.bottomButtons {
  display: flex;
  margin-top: 20px;
  gap: 30px;
}
