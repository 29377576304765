@import '../../../styling/colors.scss';
@import '../../../styling/globalStyles.scss';

.container {
  position: 'relative';
}

.buttonNotSelected {
  @extend .button;
  background-color: $color-gray-dark !important;
}

.list {
  width: 150px;
  position: absolute !important;
  background-color: $color-gray-dark !important;
  top: 51px;
  margin-left: 5px;
  padding-top: 18px !important;
  z-index: 9999;
}

.listItemHovered {
  background-color: $color-primary !important;
}
