@import '../../../styling/colors.scss';

.container {
  width: 250px;
  height: 75px;
  border: 2px dashed $color-gray-medium;
  background-color: $color-gray-light;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
