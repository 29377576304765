@import '../../../styling/colors.scss';

.container {
  height: 74px;
  padding-left: 2vw;
  background-color: $color-gray-dark !important;
  flex-direction: row !important;
  align-items: center !important;
}
.containerAccount {
  height: 40px;
  background-color: $color-gray-dark !important;
  align-items: center;
  display: flex;
  justify-content: center;
}
.link {
  color: $color-white;
}

.logo {
  max-height: 38px;
  max-width: 238px;
  cursor: pointer;
}

.buttonsContainer {
  flex-grow: 1;
}

.rightButton,
.loginButton {
  font-size: 12px !important;
  white-space: nowrap;
  color: $color-white !important;
  margin: 0 5px !important;
}

.loginButton {
  border: 1px solid $color-gray !important;
}

.loginButton:hover {
  background-color: $color-primary !important;
}
.loginButtonSelected {
  font-size: 12px !important;
  white-space: nowrap;
  color: $color-white !important;
  margin: 0 5px !important;
  background-color: $color-primary !important;
}
.buttonMobileLogout {
  color: $color-white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  text-align: center !important;
  justify-content: left !important;
  text-transform: none !important;
}