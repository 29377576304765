.animate {
  animation-name: slideInFromTop;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-delay: 0s;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-75%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
