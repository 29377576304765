@import '../../../../styling/colors.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome {
  font-size: 32px !important;
  font-weight: bold !important;
  margin-bottom: 24px !important;
}

.textField,
.button {
  margin: 10px 0 !important;
  width: 304px !important;
}

.button {
  text-transform: none !important;
  background-color: $color-primary !important;
  height: 56px;
  font-size: 16px !important;
  color: $color-white !important;
}

.no_account {
  display: flex;
  gap: 5px;
}

.text {
  align-self: flex-start;
  margin-top: 10px !important;
  margin-bottom: 10px;
  font-size: 14px;
}

.linkText {
  color: $color-primary !important;
  text-decoration: none !important;
}
